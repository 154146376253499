<app-common-modal-popup [modelSize]="'modal-xs'">

  <div class="" modelheadertext>Task Id: {{tasks?.id??0}}</div>
  <div class="container-fluid" modeldata>
      <div class="row" [formGroup]="driverForm">
          <!-- <div class="col-12">
              <div class="form-floating">
                  <select class="form-select" aria-label="Floating label select example">
                    <option selected>Select</option>                      
                  </select>
                  <label for="floatingSelect">Select teams</label>
                </div>
          </div> -->
         
              <div class="form-floating mt-2">
                  <!-- <select class="form-select" formControlName="assignedto" aria-label="Floating label select example">
                    <option value="{{d.id}}" *ngFor="let d of driversList">{{d.attributes?.did + " " + d.name}}</option>                      
                  </select>
                  <label *ngIf="assignDriver"for="floatingSelect">Assign Driver</label>
                  <label *ngIf="reassignDriver" for="floatingSelect">Reassign Driver</label> -->

                     <!-- <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Assign Driver</mat-label>
              
                    <input type="text"
                           placeholder="Select Driver"
                           (click)="filteredOption1()"
                           aria-label="Number"
                           matInput
                           [formControl]="myControl"
                           [matAutocomplete]="auto"
                           [(ngModel)]="selectedDriverId"
                           (ngModelChange)="onDriverSelectionChange()"
                           >   
                    <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.display}}
                      </mat-option>
                    </mat-autocomplete>  
                  </mat-form-field> -->

                  <!-- <mat-form-field  class="example-full-width" appearance="fill">
                    <input  type="text" placeholder="Select Driver"  matInput 
                    [formControl]="myDriverControl"
                    [matAutocomplete]="autoDriver" 
                    (click)="filteredOptionDriver()"

                     />
                     <mat-autocomplete [displayWith]="displayFnDriver" #autoDriver="matAutocomplete"
                    (optionSelected)="driverSelected($event.option.value)"
                    (closed)="onDriverAutocompleteClosed()"
                    >
                     <mat-option *ngFor="let option of filteredOptionsDriver | async" [value]="option">
                 {{ (option.attributes.cabno ? option.attributes.cabno : '') + ' ' + (option.name ? option.name : '') }}
                     </mat-option>
                     </mat-autocomplete>
                    </mat-form-field> -->

                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Select Driver</mat-label>
                      <input type="text"
                             placeholder="Select one"
                             aria-label="Number"
                             matInput
                             [formControl]="myControl"
                             [matAutocomplete]="auto">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                     >
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.display" (onSelectionChange)="driverSelected(option)">
                          {{option.display}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                </div>
             
             
        
          <div class="col-12 mt-2">
              <div class="form-floating">
                  <select class="form-select" formControlName="status" aria-label="Floating label select example">
                    <option value="unassigned">Unassigned</option>                      
                    <option value="assigned">Assigned</option>
                    <option value="acknowledged">Acknowledged</option>
                    <option value="started">Started</option>
                    <option value="completed">Completed</option>  
                                           
                  </select>
                  <label for="floatingSelect">Task Status</label>
                </div>
          </div>
          <div class="col-12 mt-2">
              <div class="form-floating">
                  <select class="form-select" formControlName="remarks" aria-label="Floating label select example"
                  >
                    <option value="0" selected>Select</option>
                    <option value="cabin">Cab In</option>                      
                    <option value="cabout">Cab Out</option>
                    <option value="separate">Separate</option>
                    <option value="backtoback">Back to Back</option> 
                    <option value="garage">Garage</option> 
                    <option value="normal">Normal</option>                     
                  </select>   
                  <span class="text-danger validation"
                  *ngIf="assignformControl.remarks.value === '0'">
                   *{{'Please select a valid remark'|translate}}
                 </span>
                  <label for="floatingSelect">Remarks</label>
                </div>
          </div>
          <div class="col-12 mt-2">
              <div class="form-floating">
                  <select class="form-select" formControlName="escort" aria-label="Floating label select example" [(ngModel)]="selectedValue" (change)="onValueChange()">
                    <option value="false">No</option>
                    <option value="true" selected>Yes</option>                     
                  </select>
                  <label for="floatingSelect">Escort</label>
                </div>
          </div>
          <div class="col-12 mt-2">
              <div class="form-floating">
                  <input type="text" class="form-control" formControlName="planned_emp" placeholder="Planned Employees" [(ngModel)]="this.tasks.attributes.planned_emp">
                  <label for="floatingSelect">Planned Employees</label>
                </div>
          </div>
          <div class="col-12 mt-2">
              <div class="form-floating">
                  <input type="text" class="form-control" formControlName="actual_emp" placeholder="Actual Employees"  [(ngModel)]="this.tasks.attributes.actual_emp">
                  <label for="floatingSelect">Actual Employees</label>
                </div>
          </div>
          <div class="col-12 mt-4 mb-3">
              <button type="button" class="btn btn-primary me-3" (click)="submitFormData()">Submit</button>
              <button type="button" class="btn btn-secondary" (click)="closeForm()">Cancel</button>
          </div>
      </div>
  </div>
</app-common-modal-popup>