import { Injectable } from '@angular/core';
import { of, forkJoin, BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment';
import { Router, UrlHandlingStrategy } from '@angular/router';
import { siteDetails } from 'src/assets/urlwiseconfig';
import * as jsonData from '../../assets/js/siteDetails.json';
import * as XLSX from 'xlsx';
declare var ExcelJS: any;
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private token = null;
  public socketConnection : any;
  isAdminLoginForOtherUser = new Subject<boolean>();
  public commonModel = new Subject<boolean>();
  public commonModelReset = new Subject<any>();
  public pageLoadInModal = new Subject<string>();
  public headerPopupEvent = new Subject<any>();
  private map: google.maps.Map | undefined;
  audio:any;
  router:Router;
  private BASE_URL = environment.apiUrl;
  //private BASE_RoasterUrl = environment.roasterUrl;
  addressBylatLong:any = {};
  private httpOptions = {};
  Language = new BehaviorSubject<string>('en');
  private apiUrl = 'https://ccbackend.tikona.in/v2/clickToCall/para';
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.httpOptions = {
};
  }
  closeSocket() {
    if(this.socketConnection){
      this.socketConnection.close();
    }
  }
  getSiteDetails() {
    let siteDomain = window.location.host;
    let data: any = (jsonData as any).default;
    let filterSiteDetails =  data.find(site=>siteDomain.indexOf(site.name) >= 0);
    return filterSiteDetails && filterSiteDetails.details ? filterSiteDetails.details : { logo:'',text:'Not Found',name:'NA'} 
  }
  getRequest(apiUrl: string, parameter: Array<string>,withoutContentType=false,withoutAuth=false) {

    return new Promise(async (resolve) => {
      const basicToken = localStorage.getItem('socketBasicToken');
      if(withoutContentType){
        this.httpOptions = {
        };
      } else {
        this.httpOptions = {
          'Content-Type': 'application/json; charset=utf8',
        };
      }
      if (!withoutAuth && basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      this.httpOptions['Accept']='application/json';

      let extraParam = '';
      if (parameter?.length) {
        extraParam = '?' + parameter.join('&');
      }
      const response = await fetch(this.BASE_URL + apiUrl + extraParam, {
        method: 'GET',
        credentials: 'include',
        headers: this.httpOptions,
      });
      if (response.ok) {
        try {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        } catch (error) {
          
        resolve({
          status: false,
          data: '',
        });
        }
      } else {
        const responseJson = await response;
        resolve({
          status: false,
          data: responseJson,
        });
      }
    });
  }

  getTasks(url1:any,assignedTo, supervisorId: string, fromDate: string, toDate: string, clientId: string,status:string): Observable<any> {
    // Construct the URL with the provided parameters
    // const url = `${this.BASE_URL}${url1}?assignedto=${assignedTo}&supervisorid=${supervisorId}&fromdate=${fromDate}&todate=${toDate}&clientid=${clientId}`;
  const basicToken = localStorage.getItem('basicToken');
  assignedTo = assignedTo != null ? assignedTo.toString().trim() : '';
  supervisorId = supervisorId != null ? supervisorId.toString().trim() : '';
  clientId = clientId != null ? clientId.toString().trim() : '';
  if (basicToken) {
    this.httpOptions['Authorization'] = 'Basic ' + basicToken;
  }
    let url = `${this.BASE_URL}${url1}?`;
    if (assignedTo !== '') {
      url += `assignedto=${assignedTo}&`;
    }
  
    if (supervisorId !== '') {
      url += `supervisorid=${supervisorId}&`;
    }
  
  
    if (fromDate !== '') {
      url += `fromdate=${fromDate}&`;
    }
    
    if (toDate !== '') {
      url += `todate=${toDate}&`;
    }

    if (clientId !== '') {
      url += `clientid=${clientId}&`;
    }

    if(status !== '')
    {
      url+=`status=${status}`
    }
    // url += `fromdate=${fromDate}&todate=${toDate}`;
    // Set up the headers
    // const headers = new HttpHeaders({
    //   'Authorization': 'Basic cGFkbWluOjEyMzQ1Ng=='
    // });

    // Make the GET request
    return this.http.get(url, { headers: this.httpOptions });
  }
  getTasksForTasklist(url1:any,assignedTo, supervisorId: string, fromDate: string, toDate: string, clientId: string,status:string,offset,limit): Observable<any> {
    // Construct the URL with the provided parameters
    // const url = `${this.BASE_URL}${url1}?assignedto=${assignedTo}&supervisorid=${supervisorId}&fromdate=${fromDate}&todate=${toDate}&clientid=${clientId}`;
  const basicToken = localStorage.getItem('basicToken');
  assignedTo = assignedTo != null ? assignedTo.toString().trim() : '';
  supervisorId = supervisorId != null ? supervisorId.toString().trim() : '';
  clientId = clientId != null ? clientId.toString().trim() : '';
  if (basicToken) {
    this.httpOptions['Authorization'] = 'Basic ' + basicToken;
  }
    let url = `${this.BASE_URL}${url1}?`;
    if (assignedTo !== '') {
      url += `assignedto=${assignedTo}&`;
    }
  
    if (supervisorId !== '') {
      url += `supervisorid=${supervisorId}&`;
    }
  
  
    if (fromDate !== '') {
      url += `fromdate=${fromDate}&`;
    }
    
    if (toDate !== '') {
      url += `todate=${toDate}&`;
    }

    if (clientId !== '') {
      url += `clientid=${clientId}&`;
    }

    if(status !== '')
    {
      url+=`status=${status}&`
    }
    if(offset !== '')
      {
        url+=`offset=${offset}&`
      }
      if(limit !== '')
        {
          url+=`limit=${limit}`
        }
    return this.http.get(url, { headers: this.httpOptions });
  }
  getTasksPagination(url1:any,assignedTo, supervisorId: string, fromDate: string, toDate: string, clientId: string,status:any,offset,limit,cabno,taskId,sortby): Observable<any> {
  // Construct the URL with the provided parameters
    // const url = `${this.BASE_URL}${url1}?assignedto=${assignedTo}&supervisorid=${supervisorId}&fromdate=${fromDate}&todate=${toDate}&clientid=${clientId}`;
    const basicToken = localStorage.getItem('basicToken');
  assignedTo = assignedTo != null ? assignedTo.toString().trim() : '';
  supervisorId = supervisorId != null ? supervisorId.toString().trim() : '';
  clientId = clientId != null ? clientId.toString().trim() : '';
  if (basicToken) {
    this.httpOptions['Authorization'] = 'Basic ' + basicToken;
  }
    let url = `${this.BASE_URL}${url1}?`;
    if (assignedTo !== '') {
      url += `assignedto=${assignedTo}&`;
    }
  
    if (supervisorId !== '') {
      url += `supervisorid=${supervisorId}&`;
    }
  
  
    if (fromDate !== '') {
      url += `fromdate=${fromDate}&`;
    }
    
    if (toDate !== '') {
      url += `todate=${toDate}&`;
    }

    if (clientId !== '') {
      url += `clientid=${clientId}&`;
    }

    if(status !== '')
    {
      url+=`status=${status}&`
    }
    if(offset !== ''){
      url+=`offset=${offset}&`
    }
    if(limit!== ''){
      url+=`limit=${limit}&`
    }
    if(cabno!== ''){
      url+=`cabNo=${cabno}&`
    }
    if(taskId!== ''){  
      url+=`taskId=${taskId}&`
    }
    if(sortby!== ''){  
      url+=`sortBy=${sortby}&`
    }
  
    // url += `fromdate=${fromDate}&todate=${toDate}`;
    // Set up the headers
    // const headers = new HttpHeaders({
    //   'Authorization': 'Basic cGFkbWluOjEyMzQ1Ng=='
    // });

    // Make the GET request
    return this.http.get(url, { headers: this.httpOptions });
  }
  getTasksforFilter(url1:any,assignedTo, supervisorId: string, fromDate: string, toDate: string, clientId: string,status:string,type,offset,limit): Observable<any> {

    const basicToken = localStorage.getItem('basicToken');
    assignedTo = assignedTo != null ? assignedTo.toString().trim() : '';
    supervisorId = supervisorId != null ? supervisorId.toString().trim() : '';
    clientId = clientId != null ? clientId.toString().trim() : '';
    if (basicToken) {
      this.httpOptions['Authorization'] = 'Basic ' + basicToken;
    }
      let url = `${this.BASE_URL}${url1}?`;
      if (assignedTo !== '') {
        url += `assignedto=${assignedTo}&`;
      }
    
      if (supervisorId !== '') {
        url += `supervisorid=${supervisorId}&`;
      }
    
    
      if (fromDate !== '') {
        url += `fromdate=${fromDate}&`;
      }
      
      if (toDate !== '') {
        url += `todate=${toDate}&`;
      }
  
      if (clientId !== '') {
        url += `clientid=${clientId}&`;
      }
  
      if(status !== '')
      {
        url+=`status=${status}&`
      }
      if(type !== ''){
        url+=`type=${type}&`
      }
      if (limit !== '') {
        url += `limit=${limit}&`;
      }
      if (offset !== '') {
        url += `offset=${offset}`;
      }
      return this.http.get(url, { headers: this.httpOptions });
    }

  getTasksForSupervisor(url1:any,fromDate: string,toDate: string,assignedTo: string,clientId: string,status: any,offset,limit,sortBy): Observable<any>{
    const basicToken = localStorage.getItem('socketBasicToken');
    assignedTo = assignedTo != null ? assignedTo.toString().trim() : '';
    clientId = clientId != null ? clientId.toString().trim() : '';
    if (basicToken) {
      this.httpOptions['Authorization'] = 'Basic ' + basicToken;
    }
      let url = `${this.BASE_URL}${url1}?`;
      if(status !== '')
      {
        url+=`status=${status}&`
      }
      if (fromDate !== '') {
        url += `fromdate=${fromDate}&`;
      }
      if (toDate !== '') {
        url += `todate=${toDate}&`;
      }
      if (assignedTo !== '') {
        url += `assignedto=${assignedTo}&`;
      }
      if (clientId !== '') {
        url += `clientId=${clientId}&`;
      }
      if(offset !== ''){
        url+=`offset=${offset}&`
      }
      if(limit!== ''){
        url+=`limit=${limit}&`
      }
      if(sortBy!== ''){
        url+=`sortBy=${sortBy}`
      }
      return this.http.get(url, { headers: this.httpOptions });
  }
  
  getTaskListforExpense(url1:any): Observable<any>{
    const basicToken = localStorage.getItem('socketBasicToken');
    // assignedTo = assignedTo != null ? assignedTo.toString().trim() : '';
    // supervisorId = supervisorId != null ? supervisorId.toString().trim() : '';
    // clientId = clientId != null ? clientId.toString().trim() : '';
    if (basicToken) {
      this.httpOptions['Authorization'] = 'Basic ' + basicToken;
    }
      let url = `${this.BASE_URL}${url1}`;
      return this.http.get(url, { headers: this.httpOptions });
  }
  // getRequestRoaster(apiUrl: string, parameter: Array<string>,withoutContentType=false,withoutAuth=false) {
  //   return new Promise(async (resolve) => {
  //     const basicToken = localStorage.getItem('basicToken');
  //     if(withoutContentType){
  //       this.httpOptions = {
          
  //       };
  //     } else {
  //       this.httpOptions = {
  //         'Content-Type': 'application/json; charset=utf8',
  //       };
  //     }
  //     if (!withoutAuth && basicToken) {
  //       this.httpOptions['Authorization'] = 'Basic ' + btoa('rostersuperviosr:123456');
  //     }
  //     this.httpOptions['Accept']='application/json';

  //     let extraParam = '';
  //     if (parameter?.length) {
  //       extraParam = '?' + parameter.join('&');
  //     }
  //     const response = await fetch(this.BASE_RoasterUrl + apiUrl + extraParam, {
  //       method: 'GET',
  //       credentials: 'include',
  //       headers: this.httpOptions,
  //     });
  //     if (response.ok) {
  //       try {
  //         const responseJson = await response.json();
  //         resolve({
  //           status: true,
  //           data: responseJson,
  //         });
  //       } catch (error) {
          
  //       resolve({
  //         status: false,
  //         data: '',
  //       });
  //       }
  //     } else {
  //       const responseJson = await response;
  //       resolve({
  //         status: false,
  //         data: responseJson,
  //       });
  //     }
  //   });
  // }
  postRequest(apiUrl: string, body: any) {
    return new Promise(async (resolve,reject) => {
      this.httpOptions = {
        'Content-Type': 'application/json; charset=utf8',
      };
      const basicToken = localStorage.getItem('basicToken');
      const socketBasicToken = localStorage.getItem('socketBasicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      else if(socketBasicToken){
        this.httpOptions['Authorization'] = 'Basic ' + socketBasicToken; 
      }

      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
        headers: this.httpOptions,
      });
      if (response.ok) {
        if(response.status == 204) {
          resolve({
            status: true,
            data: '',
          });
        } else {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        }
        
      } else {
        const responseJson = await response;
        reject({
          status: false,
          data: responseJson,
        });
      }
    });
  }
  postRequestForFile(apiUrl: string, body: FormData) {

    return new Promise(async (resolve,reject) => {
      this.httpOptions = {};
      debugger
      const basicToken= localStorage.getItem('socketBasicToken');
      // const basicToken = localStorage.getItem('basicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }

      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'POST',
        credentials: 'include',
        body: body,
        headers: this.httpOptions,
      });
      if (response.ok) {
        if(response.status == 204) {
          resolve({
            status: true,
            data: '',
          });
        } else {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        }
        
      } else {
        const responseJson = await response;
        reject({
          status: false,
          data: responseJson,
        });
      }
    });
  }
  putRequest(apiUrl: string, body: any) {
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    return new Promise(async (resolve,reject) => {
      const basicToken = localStorage.getItem('socketBasicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }

      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(body),
        headers: this.httpOptions,
      });
      if (response.ok) {
        const responseJson = await response.json();
        resolve({
          status: true,
          data: responseJson,
        });
      } else {
        reject({
          status: false,
          data: await response.json(),
        });
      }
    });
  }
  putRequestForTask(apiUrl: string, body: any) {
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    return new Promise(async (resolve, reject) => {
      const basicToken = localStorage.getItem('socketBasicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
  
      try {
        const response = await fetch(this.BASE_URL + apiUrl, {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(body),
          headers: this.httpOptions,
        });
  
        let responseData: any;
  
        if (response.ok) {
          responseData = await response.json();
          resolve({
            status: true,
            data: responseData,
          });
        } else {
          // Attempt to parse JSON error body
          try {
            responseData = await response.json();
          } catch {
            // If parsing fails, use plain text response
            responseData = await response.text();
          }
  
          reject({
            status: false,
            data: responseData,
          });
        }
      } catch (error) {
        reject({
          status: false,
          data: 'An unexpected error occurred.',
        });
      }
    });
  }
  updateExpenses(apiurl:string,formData: FormData){
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    const basicToken = localStorage.getItem('socketBasicToken');
    if (basicToken) {
      this.httpOptions['Authorization'] = 'Basic ' + basicToken;
    }
  

    return this.http.put<any>(`${this.BASE_URL}${apiurl}`,formData,this.httpOptions );
  }
  otherUserLogin(userId,adminId=0) {
    this.getRequest('session/'+ userId,null,true).then((resp: any) => {
      this.getRequest('server',null,true).then((resp: any) => {
        this.getRequest('session',null,true,true).then((resp: any) => {
          if (resp?.status) {
            this.setAdminOtherLogin(resp.data,adminId)
            
          }
        });
      });
    });
  }
  setAdminOtherLogin(responseData,adminId){
    
    localStorage.setItem('temp_adm_user_id',adminId);
    localStorage.setItem('Admin_User_Id',  localStorage.getItem('userData'));
    localStorage.setItem('Admin_token', localStorage.getItem('token'));

    localStorage.setItem('userData', JSON.stringify(responseData));
    localStorage.setItem('token', responseData.token);
    this.isAdminLoginForOtherUser.next(adminId ? true:false);
  }
  backToAdminSession(adminId){
    localStorage.removeItem('temp_adm_user_id');
  }
  deleteRequest(apiUrl: string, body: any = null) {
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    return new Promise(async (resolve) => {
      const basicToken= localStorage.getItem('socketBasicToken'); 
      // const basicToken = localStorage.getItem('basicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      try {


        
        let dataSet = {
          method: 'DELETE',
          credentials: 'include',
          headers: this.httpOptions,
        };

        if (body) {
          dataSet['body'] = JSON.stringify(body);
        }
        const response = await fetch(this.BASE_URL + apiUrl, {
          method: 'DELETE',
          credentials: 'include',
          headers: this.httpOptions,
          body : (body? JSON.stringify(body):'')
        });
        if (response.ok) {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        } else {
          resolve({
            status: false,
            data: response,
          });
        }
      } catch (error) {
        resolve({
          status: false,
          data: null,
        });
      }
    });
  }
  deleteRequestForDriver(apiUrl: string, body: any = null) {
    debugger
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    return new Promise(async (resolve) => {
      const basicToken= localStorage.getItem('socketBasicToken'); 
      // const basicToken = localStorage.getItem('basicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      try {


        
        let dataSet = {
          method: 'DELETE',
          credentials: 'include',
          headers: this.httpOptions,
        };

        if (body) {
          dataSet['body'] = JSON.stringify(body);
        }
        const response = await fetch(this.BASE_URL + apiUrl, {
          method: 'DELETE',
          credentials: 'include',
          headers: this.httpOptions,
          body : (body? JSON.stringify(body):'')
        });
        if (response.ok) {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        } else {
          resolve({
            status: false,
            data: response,
          });
        }
      } catch (error) {
        resolve({
          status: false,
          data: null,
        });
      }
    });
  }
  deleteResource(url: string): Observable<any> {
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    const basicToken = localStorage.getItem('basicToken');
      const socketBasicToken = localStorage.getItem('socketBasicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      else if(socketBasicToken){
        this.httpOptions['Authorization'] = 'Basic ' + socketBasicToken; 
      }
    return this.http.delete<any>(this.BASE_URL+url,  this.httpOptions );
  }

  postRequestSesstion(apiUrl,body) {
    let _url = this.BASE_URL + apiUrl;
    
  let options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    withCredentials: true
  };
      return this.http.post<any>(_url,body,options);
  }
  

  postFormRequest(apiUrl: string, body: any,isAuth = false) {
    return new Promise(async (resolve) => {
      const basicToken = localStorage.getItem('basicToken');
      
      let header = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf8',
      }
      if (basicToken && isAuth) {
        header['Authorization'] = 'Basic ' + basicToken;
      }
      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'POST',
        credentials: 'include',
        body: body,
        headers: header,
      });
      if (response.ok) {
        const responseJson = await response.json();
        resolve({
          status: true,
          data: responseJson,
        });
      } else {
        resolve({
          status: false,
          data: response,
        });
      }
    });
  }

  getUserDetails() {
    let userDetails = {};
    let user = localStorage.getItem('userData');
    if (user) {
      userDetails = JSON.parse(user);
    }
    return of(userDetails);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any) => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  getDeviceAddress(latitude,longitude,deviceId = '') {
    if(!(latitude && longitude&& this.addressBylatLong[latitude+'_'+longitude])){
      let params = [
        'latitude=' + longitude,
        'longitude=' + latitude,
        'maxDistance=1000'
      ];
      if (latitude && longitude) {
        this
        .getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => {
          if(resp.data && resp.data.address){
            if(deviceId) {
              this.addressBylatLong[deviceId] = resp.data.address;
          
            }
            this.addressBylatLong[latitude+'_'+longitude] = resp.data.address;
          }
        }).finally(()=>{
         
        });
    }
    }
    

    
  }
  playAudio(notifSignal){
    if(notifSignal == 3){
      this.audio = new Audio();
      this.audio.src = 'assets/audio/notification.mp3';
      this.audio.play();
      Audio.prototype.stop = function()
      {
          this.pause();
          this.currentTime = 0.0;
      }
    }else{
      this.audio = new Audio();
      this.audio.src = 'assets/audio/notification.mp3';
      this.audio.play();
    }
  }
  // getWorldTimeZoneList() {
  //   return this.http.get("http://worldtimeapi.org/api/timezone", {headers: this.httpOptions});
  // }

  // calltoDriver(body: any) {
  //   return new Promise(async (resolve,reject) => {
  //    this.httpOptions = {
  //       'Content-Type': 'application/json',
  //       'cache-control': 'no-cache'
  //     };

  //     const response = await fetch('https://ccbackend.tikona.in/v2/clickToCall/para', {
  //       method: 'POST',
  //       headers: this.httpOptions,
  //       body: null,
  //       params: {
  //         user_id : '75694186',
  //         token: '6TDHMWLh6XbU5kC3THsh',
  //         from: 'FROM_NUMBER',
  //         to: 'TO_NUMBER'
  //       }
  
  //     });
  //     if (response.ok) {
  //       if(response.status == 204) {
  //         resolve({
  //           status: true,
  //           data: '',
  //         });
  //       } else {
  //         const responseJson = await response.json();
  //         resolve({
  //           status: true,
  //           data: responseJson,
  //         });
  //       }
        
  //     } else {
  //       const responseJson = await response;
  //       reject({
  //         status: false,
  //         data: responseJson,
  //       });
  //     }
  //   });
  // }


  makePostRequest(user_id: string, token: string, from: any, to: string): Observable<any> {
    const options = {
      headers: {
        'cache-control': 'no-cache'
      }
    };

    const queryParams = `?user_id=${user_id}&token=${token}&from=${from}&to=${to}&fromStrategy=ONE_BY_ONE`;

    return this.http.post(`${this.apiUrl}${queryParams}`, null, options);  
  }
  getDirections(apiKey: string, origin: string, destination: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=${apiKey}`;

    return this.http.get(apiUrl);
  }
  getDirections1(apiKey: string, origin: string, destination: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}`;
    return this.http.get(apiUrl);
  }
  getDirections2(origin: string, destination: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}`;
    return this.http.get(apiUrl);
  }

  initMap(startLat: number, startLon: number, endLat: number, endLon: number): void {
    const origin = new google.maps.LatLng(startLat, startLon);
    const destination = new google.maps.LatLng(endLat, endLon);

    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: 10,
      center: origin
    });

    // Draw the route
    this.getDirections1('AIzaSyCyxa0d5VJiM4MCBrdLrSCa_CRA0TTYhpA', `${startLat},${startLon}`, `${endLat},${endLon}`)
      .subscribe(
        response => {
          const directionsRenderer = new google.maps.DirectionsRenderer();
          directionsRenderer.setMap(this.map as google.maps.Map);
          directionsRenderer.setDirections(response);
        },
        error => {
          console.error('Error getting directions:', error);
        }
      );
  }

  private apiUrl1 = 'https://signzy.tech/api/v2/snoops';

 


  getTimelineTasks(url1:any): Observable<any> {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0] + 'T00:00:00Z';
    const formattedToday1 = today.toISOString().split('T')[0] + 'T23:59:59Z';
    // const startOfDay = new Date(today);
    // startOfDay.setHours(0, 0, 0, 0);
    // // const fromDate = startOfDay.toISOString();
    
    // // End of the day (23:59:59.999)
    // const endOfDay = new Date(today);
    // endOfDay.setHours(23, 59, 59, 999);
    // // const toDate = endOfDay.toISOString();
    //  const fromDate = '2024-01-01T00:00:00Z';
    //  const toDate = '2024-01-18T00:00:00Z';
    const headers = new HttpHeaders({   
      'Content-Type': 'application/json',
    });

    const url = `${this.BASE_URL}${url1}?fromdate=${formattedToday}&todate=${formattedToday1}`;

    return this.http.get(url, { headers });
  }
 createTask(url1:any,body:any): Observable<any> {
 
  const basicToken = localStorage.getItem('socketBasicToken');
  this.httpOptions['Authorization'] = 'Basic ' + basicToken;
  this.httpOptions['Content-Type']='application/json'
    const headers = new HttpHeaders({   
      'Content-Type': 'application/json',
    });


    const url = `${this.BASE_URL + url1}`;

    return this.http.post(url,body, { headers:this.httpOptions });
  }

  v2_snoops(qr: any) {
    let myHeaders: any = new Headers();

    myHeaders.append('Content-Type', 'application/json');


    let requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(qr),
      redirect: 'follow',
    };

    return fetch('https://preproduction-persist.signzy.tech:4200/api/v2/snoops', requestOptions).then(
      (response) => response.json()
    );
  }

  getPatrons(qr: any) {
    let myHeaders: any = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(qr),
      redirect: 'follow',
    };

    return fetch(
      'https://preproduction-persist.signzy.tech:4200/api/v2/patrons/login',
      requestOptions
    ).then((response) => response.json());
  }
  async json_to_xlsx(jsonData: any, titel: any, fileName: any = 'download') {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    const headerStyle = {
      fill: {
        fgColor: { rgb: 'FFFF00' }, // Yellow background color
      },
      font: {
        bold: true,
      },
    };

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 }); // Headers are in the first row
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].t = headerStyle;
      console.log(worksheet[cellAddress]);
    }

    // XLSX.utils.book_append_sheet(workbook, worksheet, screen);
    XLSX.writeFile(workbook, fileName + '.xlsx');

    // Create a new workbook and worksheet


    // const workbook = new ExcelJS.Workbook();
    // const worksheet = workbook.addWorksheet('Sheet1');

    // // Add a title row
    // const titleRow = worksheet.addRow([titel]);
    // titleRow.font = {
    //   name: 'Arial',
    //   size: 16,
    //   bold: true,
    // };
    // titleRow.alignment = {
    //   horizontal: 'center',
    // };
    // worksheet.mergeCells('A1:C1');

    // // Add some space between the title and the headers
    // worksheet.addRow([]);

    // // Get the headers from the JSON keys
    // const headers = Object.keys(jsonData[0]);
    // const headerRow = worksheet.addRow(headers);

    // // Define the header style
    // const headerStyle = {
    //   font: {
    //     bold: true,
    //   },
    //   alignment: {
    //     vertical: 'middle',
    //     horizontal: 'center',
    //   },
    //   fill: {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: {
    //       argb: 'FFFF00',
    //     }, // Yellow background color
    //   },
    // };

    // // Apply the style to each cell in the header row
    // headerRow.eachCell((cell: any) => {
    //   cell.style = headerStyle;
    // });

    // // Add the data rows
    // jsonData.forEach((item: any) => {
    //   worksheet.addRow(Object.values(item));
    // });

    // // Adjust the column widths
    // worksheet.columns.forEach((column: any) => {
    //   let maxLength = 0;
    //   column.eachCell(
    //     {
    //       includeEmpty: true,
    //     },
    //     (cell: any) => {
    //       const columnLength = cell.value ? cell.value.toString().length : 10;
    //       if (columnLength > maxLength) {
    //         maxLength = columnLength;
    //       }
    //     }
    //   );
    //   column.width = maxLength < 10 ? 10 : maxLength;
    // });

    // // Generate the Excel file as a blob
    // const buffer = await workbook.xlsx.writeBuffer();
    // const blob = new Blob([buffer], {
    //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // });

    // // Create a link element and trigger the download
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;

    // a.download = fileName + new Date().getTime() + '.xlsx';
    
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);
  }

}
