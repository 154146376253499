import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { Subscription } from 'rxjs';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { CommonService } from '../../services/common.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { SubjectSubscribeService } from 'src/app/services/subject-subscribe.service';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [MeterTokm]
})
export class DashboardComponent implements OnInit, AfterViewInit {
  totalVehicles = 0;
  running = 0;
  fuelPriceList = [];
  displayFuelPrice:any= {};

  Idling = 0;
  Stopped = 0;
  outofreach = 0;
  expired = 0;
  nodata = 0;
  towwed = 0;
  dieselPrice = '';
  petrolPrice = '';
  state = '';
  vehicleData = [];
  vehicleDateArray = [];
  vehicleDistanceArray = [];
  distanceCount = [];
  totalVehicleDistance = '';
  totalVehicleFuelConsumption = '';
  totalVehicleExpense = '';
  loadCountSubscriber:any;
  options: any = {};
  optionss: any = {};
  optionss1:any={};
  optionss2:any={};
  odometerOption: any = {};
  vehicleSub: Subscription;
  lastSevenDaysSubscriber$: Subscription;
  deviceId = '';
  showDropdown: boolean = false;
  showAssignedDropdown: boolean = false;
  showAcknowledgedDropdown: boolean = false;
  showStartedDropdown: boolean = false;
  showCompletedDropdown: boolean = false;
  showCancelledDropdown: boolean = false;
  showUnassignedDropdown: boolean = false;
  totalRecordCountByClient: any[]=[];
  selectedOption = 0;
  @ViewChild('scrollableContent') scrollableContent: ElementRef;
  custom = {
    value: '',
  };

  states =
  [
    'Gujarat',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry',
  ];

  customs = [{ value: 'demo1' }, { value: 'demo1' }, { value: 'demo1' }];
  eventsCountsList: any[] = [];
  eventStatusCount: any[] = [];
  taskCountByStatus: any[]= [];
  acknowledgedCount: any = 0;
  assignedCount: any = 0;
  inprogressCount: any=0;
  unassignedCount: any=0;
  rejectedCount: any=0;
  failedCount: any=0;
  completedCount: any =0;
  totalRecordCount: any =0;
  startedCount: any = 0;
  unassignedTaskList: any[]=[];
  acknowledgedTaskList:any[]=[];
  startedTaskList:any[]=[];
  completedTaskList:any[]=[];
  totalTaskList:any[]=[];
  assignedTaskList:any[]=[];
  cancelledTaskList:any[]=[];
  constructor(
    private dash_http: DashboardDataService,
    private commonService: CommonService,
    private router: Router,
    private subjectSubscribeService: SubjectSubscribeService,
    private meterTokm: MeterTokm,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.getOdometerData();
    this.displayLast7DaysGraph();
    this.getTasksStatusCount();
    this.getTasksCountDaywise();
    this.getCountByStatus();
    this.getTopDriverPerformance();
    this.getDeviceList();
    this.loadCountSubscriber =  setInterval(()=>{
    }, 2000);
   
    this.lastSevenDaysSubscriber$ =  this.subjectSubscribeService.DeviceId.subscribe((resp: any) => {
      if (resp) {
  
      }
    });
  }

  getFulePrice() {
    this.commonService.getRequest('multitrack/getFuelPrices',[]).then((resp: any) => {
      if (resp?.status) {
        if(resp.data && resp.data.results) {
          this.fuelPriceList = resp.data.results;
          this.getFuelPrice_state(true);
        }
      }
    })
  }
  getDeviceList() {
    
    this.commonService
      .getRequest('devices/getDeviceStatus', [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.totalVehicles = resp.data.RUNNING + resp.data.IDLE +resp.data.STOPPED +resp.data.EXPIRED+resp.data.NOT_REPORTING + resp.data.NO_DATA + resp.data.TOWWED;
          this.running = resp.data.RUNNING;
          this.Idling = resp.data.IDLE;
          this.Stopped = resp.data.STOPPED;
          this.outofreach = resp.data.NOT_REPORTING;
          this.expired = resp.data.EXPIRED;
          this.nodata = resp.data.NO_DATA;
          this.towwed = resp.data.TOWWED;
        }
      });
  }

  getOdometerData() {
    this.commonService
      .getRequest('dashboard/drivers/top/distance', [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.odometerOption.series[0].data = [];
          if (resp?.status) {
            for (let key in resp.data) {              
              this.odometerOption.series[0].data.push(this.meterTokm.transform(resp.data[key]['distanceInKm']));
              this.odometerOption.xaxis.categories.push(resp.data[key]['name']);
            }            
          }
          var chart = new ApexCharts(
            document.querySelector('#chartOdometer'),
            this.odometerOption
          );
          chart.render();
          chart.resetSeries();
        }      
      });
  }
  initiateCarousel() {
    $('#eventCarousel').owlCarousel({
      loop: false,
      nav: true,
      autoWidth: false,
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    });
  }
  getEventsData() {
    let now = moment();
    let startTime = now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString();
    let endTime = now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString();  
    this.commonService
      .getRequest(`reports/geteventcounts?from=${new Date(startTime).toISOString()}&to=${new Date(endTime).toISOString()}`, [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.eventsCountsList = resp.data;
          setTimeout(() => {
            this.initiateCarousel();
          }, 100);
        }      
      });
  }
  

  redirectTrackerUrl(type) {

    console.log('type', type);
    if (type === 'All') {
      this.router.navigate(['/main/tracking']);
    } else {
      this.router.navigateByUrl('/main/tracking?type=' + type);
    }
  }


  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) =>
        this.showPosition(position)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  showPosition(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    this.dash_http.getState(latitude, longitude).subscribe((resp) => {
      this.state = resp.results[0].address_components.filter(
        (ac) => ~ac.types.indexOf('administrative_area_level_1')
      )[0].long_name;
      console.log(this.state);
      this.getFuelPrice_state();
    });
  }
  displayLast7DaysGraph() {
    this.commonService
      .getRequest('dashboard/tasks/daywise', [])
      .then((resp: any) => {
        this.optionss1.series[0].data = this.formatChartData(resp.data);
        this.renderChart();
      });
  }


  displayLast1monthGraph() {

    this.commonService
        .getRequest('dashboard/tasks/client/month', [])
        .then((resp: any) => {
            const formattedData = this.formatChartDataforClientTaskCount(resp.data); // Format the data
            this.optionss2.series[0].data = formattedData;
            console.log('client tasks', this.optionss2.series[0].data);

            this.renderChartforClientTaskCount();
        });
}
onChange(){
  if(this.selectedOption == 1){
    this.displayLast1monthGraph()
  }
  else if(this.selectedOption == 0){
    this.displayTodaysTasksGraph();
  }
}
displayTodaysTasksGraph(){
  const formattedData = this.formatChartDataforClientTodayTasks(this.totalRecordCountByClient); // Format the data
  this.optionss2.series[0].data = formattedData;
  console.log('client tasks', this.optionss2.series[0].data);
  this.renderChartforClientTaskCount();
}

  formatChartData(data: any[]): any[] {
    return data.map(item => {
      return {
        x: item.taskDate,
        y: item.recordCount
      };
    });
  }


  formatChartDataforClientTaskCount(data: any[]): any[] {

    return data.map(item => {
        return {
            x: item.id.toString(), 
            y: item.recordCount
        };
    });
}
formatChartDataforClientTodayTasks(data: any[]): any[] {
  return data.map(item => {
      return {
          x: item.clientName, 
          y: item.recordCount
      };
  });
}

  renderChart() {
    var chart = new ApexCharts(
      document.querySelector('#chart2'),
      this.optionss1
    );
    chart.render();
  }
  renderChartforClientTaskCount() {
    var chart = new ApexCharts(
      document.querySelector('#chart3'),
      this.optionss2
    );
    chart.render();
  }
  
 

  updateDashboard_dataonRefresh() {
    this.dash_http.vehiclesCountComponent.subscribe((res) => {

    });
  }

  getFuelPrice_state(isFirst=false) {
    if((this.state || isFirst)  && this.fuelPriceList.length) {
      let fuelData = isFirst ?this.fuelPriceList[0]:  this.fuelPriceList.find(fuleState=>fuleState.cityState.toLowerCase() == this.state.toLowerCase())
      if(fuelData){
        if(isFirst) {
          this.state = fuelData.cityState;
        }
        this.petrolPrice = fuelData.petrolPrice ?parseFloat(fuelData.petrolPrice).toFixed(2) : 'NA';
        this.dieselPrice = fuelData.dieselPrice ?parseFloat(fuelData.dieselPrice).toFixed(2) : 'NA';
      };
    }else {
      this.petrolPrice = 'NA';
      this.dieselPrice = 'NA';
    }
  }
  ngAfterViewInit(): void {
    var options = {
      series: [
        {
          name: 'series1',
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        
      ],
      chart: {
        height: 250,
        type: 'area',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    };

    var chart = new ApexCharts(document.querySelector('#chart1'), options);
    chart.render();

    this.optionss = {
      series: [
        {
          name: 'Manoj',
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            '<ul>' +
            '<li style="margin-top:2vh;"><b>Date </b>: ' +
            data.x +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '<li><b>Distance (Kms) </b>: ' +
            data.y +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '</ul>'
          );
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ['#12a370']
       
      },
      xaxis: {
        categories: [],
        type: 'date',
        title: {
          text: 'Date',
          offsetX: 0,
          offsetY: 7,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
      yaxis: {
        floating: false,
        decimalsInFloat: undefined,
        title: {
          text: 'Distance (Kms)',
          rotate: -90,
          offsetX: -10,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
      },
    };
    this.optionss1 = {
      series: [{
        name: 'Tasks Count',
        data: []
      }],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: 'category',
      },
      yaxis: {
        title: {
          text: 'Tasks Count'
        }
      },
    };
 
    this.optionss2 = {
      series: [{
          name: 'Tasks Count',
          data: []
      }],
      chart: {
          type: 'bar',
          height: 400,
      },
      plotOptions: {
          bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
          },
      },
      dataLabels: {
          enabled: false
      },
      xaxis: {
          type: 'category', // Ensure the x-axis type is set to 'category'
          categories: [],
          title: {
              text: 'Company',
              offsetX: -10,
              offsetY: 5,
              // Adjust offsetY to control the position of the x-axis title
              style: {
                  color: undefined,
                  fontSize: '2.5vh',
                  fontFamily: '-webkit-pictograph',
                  fontWeight: 600,
                  cssClass: 'apexcharts-xaxis-title',
              },
          },
      },
      yaxis: {
          title: {
              text: 'Tasks Count'
          }
      },
  };
  
  
    var chart2 = new ApexCharts(
      document.querySelector('#chart2'),
      this.optionss1
    );
    chart2.render();
    var chart3 = new ApexCharts(
      document.querySelector('#chart3'),
      this.optionss2
    );
    chart3.render();

    //Odometer chart render
    this.odoMeterChartRender();
  }

  odoMeterChartRender() {
    // CHART 2

    this.odometerOption = {
      series: [
        {
          name: 'Odometer',
          data: [],
        },
      ],      
      chart: {
        height: 350,
        type: 'bar',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      grid: {
        show: false
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          console.log('odometer');
          console.log(series, seriesIndex, dataPointIndex, w);
          var data = w.globals.labels[dataPointIndex];
          return (
            '<ul>' +
            '<li style="margin-top:2vh;"><b>Drivers </b>: ' +
            data +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '<li><b>Distance (Kms) </b>: ' +
            series[0][dataPointIndex] +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '</ul>'
          );
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"]
        },
        formatter: function(val, opt) {
          return val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      fill: {
        colors: ['#12a370']
      },
      xaxis: {
        categories: [],
        type: 'number',        
        title: {
          text: 'Distance (Kms)',
          offsetX: 0,
          offsetY: 7,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
      yaxis: {
        floating: false,
        decimalsInFloat: undefined,        
        title: {
          text: 'Drivers',
          rotate: -90,
          offsetX: -10,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
      },
    };

    var chartOdometer = new ApexCharts(
      document.querySelector('#chartOdometer'),
      this.odometerOption
    );
    chartOdometer.render();
  }



  getTasksStatusCount(){
    this.commonService.getRequest('dashboard/tasks/today/status', []).
    then((resp: any) => {
      this.taskCountByStatus = resp.data;
      this.taskCountByStatus.forEach(entry => {
        switch (entry.status.toLowerCase()) {
          case 'acknowledged':
            this.acknowledgedCount = entry.recordCount;
            break;
          case 'assigned':
            this.assignedCount = entry.recordCount;
            break;
          case 'inprogress':
            this.inprogressCount = entry.recordCount;
            break;
          case 'unassigned':
              this.unassignedCount = entry.recordCount;
              break;
          case 'rejected':
                this.rejectedCount = entry.recordCount;
                break;
          case 'failed':
                  this.failedCount = entry.recordCount;
                  break; 
          case 'completed':
            this.completedCount = entry.recordCount;        
             break;
          case 'started':
            this.startedCount = entry.recordCount
            break;
        }
        this.totalRecordCount += entry.recordCount;
      });
  })
  this.commonService.getRequest('dashboard/tasks/today/status/client', []).
  then((resp: any) => {
    resp.data.forEach(task => {
      switch (task.status) {
        case 'unassigned':
          this.unassignedTaskList.push(task);
          break;
        case 'acknowledged':
          this.acknowledgedTaskList.push(task);
          break;
        case 'started':
          this.startedTaskList.push(task);
          break;
        case 'completed':
          this.completedTaskList.push(task);
          break;
        case 'assigned':
          this.assignedTaskList.push(task);
          break;
        case 'cancelled':
          this.cancelledTaskList.push(task);
          break;
        default:

          break;
      }
    });
    this.totalRecordCountByClient = this.calculateTotalRecordCount(resp.data);
    this.displayTodaysTasksGraph();
    console.log('client Record',this.totalRecordCountByClient)
  })

}
calculateTotalRecordCount(data) {
  const totals = {};

  data.forEach(item => {
    const { clientName, recordCount } = item;
    if (!totals[clientName]) {
      totals[clientName] = 0;
    }
    totals[clientName] += recordCount;
  });

  // Convert totals object to array of objects
  return Object.keys(totals).map(clientName => ({
    clientName,
    recordCount: totals[clientName]
  }));
}

getTasksCountDaywise(){
  
  this.commonService.getRequest('dashboard/tasks/daywise', []).
  then((resp: any) => {

    console.log('taskcountbyDayWise',resp)
})
}

getTopDriverPerformance(){
  
  this.commonService.getRequest('dashboard/drivers/top/distance', []).
  then((resp: any) => {

    console.log('top drivers',resp)
})
}

getCountByStatus(){
  
  this.commonService.getRequest('dashboard/drivers/status', []).
  then((resp: any) => {

    this.eventStatusCount=resp.data

    setTimeout(() => {
      this.initiateCarousel();
    }, 100);
    console.log('task count by Status=>',resp)
    console.log('task count by Status=>', this.eventStatusCount)
    
})
}

redirectDriverUrl(type){
  console.log('type', type);
  if (type === 'All') {
    this.router.navigate(['/main/tracking']);
  } else {
    this.router.navigateByUrl('/main/tracking?type=' + type);
  }
}

toggleDropdown(status:any) {
  switch(status){
case "total":
  this.showDropdown = !this.showDropdown;

  break;
  case 'unassigned':
    this.showUnassignedDropdown = !this.showUnassignedDropdown;

    break;
  case 'acknowledged':
    this.showAcknowledgedDropdown = !this.showAcknowledgedDropdown;
    break;
  case 'started':
    this.showStartedDropdown = !this.showStartedDropdown;
    break;
  case 'completed':
    this.showCompletedDropdown = !this.showCompletedDropdown;
    break;
  case 'assigned':
    this.showAssignedDropdown = !this.showAssignedDropdown;
    break;
  case 'cancelled':
    this.showCancelledDropdown = !this.showCancelledDropdown;
    break;
  default:
    break;

  }
  
}

}
