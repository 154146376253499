<nav class="navbar navbar-expand-sm navbar-dark bg-light fixed-top p-0">
  <div class="container-fluid">
    <a class="navbar-brand" href="javascript:void(0)">
      <div class="profile-info">
        <div class="">
          <!-- <img src="{{siteDetails.logo}}" class="" alt="logo" routerLink="/main/dashboard"> -->
          <img src="/assets/images/ptrack_logo2.jpeg" class="" alt="logo" routerLink="/main/dashboard">
          <!-- <img
            src="/assets/icons/dashboard.svg"
            alt="user"
            routerLink="/main/dashboard"
          /> -->
        </div>
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div style="text-align: -webkit-right;" [class.is_Back_To_Admin]="isBackToAdmin" class=" 
        p-0
          offset-lg-0 offset-md-0 offset-sm-0
        col-xl-3 col-xxl-3 col-lg-3 col-md-6 col-6
        order-3
      ">
      
      <div class="user_profile">
        <button *ngIf="isBackToAdmin" (click)="backToAdmin()" title="Back To Admin" type="button" class="m-2 btn btn-outline-dark"> <i class="fa fa-sign-out pl-2" aria-hidden="true"></i></button>
        <div class="user-text">
          <!-- <span class="username">{{ username }}</span> -->
          <!-- <span class="username d-flex"><small class=" me-2">{{ username }}</small>(<span *ngIf="userDetails && !userDetails.administrator && userDetails.userLimit !== 0" class="userid"><i class="text-warning fas fa-coins"></i> {{userDetails.availableLicenses ? userDetails.availableLicenses : 0 }}</span>)</span>           -->
          <span class="username d-flex">{{ username }}</span>
        </div>

        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src="/assets/images/profile.png" style="width: 5vh;" alt="profile" matTooltip="Profile" />
          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" style="border-top: 3px solid #fff"
              (click)="openModel('changepassword')">
                <img src="/assets/icons/changepassword.png" alt="changepassword" class="navIcons" />
                <span class="changepassword" title="{{'Change Password'|translate}}"> {{'Change Password'|translate}} </span>
              </a>
            </li>
            <!-- <li>
              <a class="dropdown-item">
                <img src="/assets/icons/customer_support.png" alt="changepassword" class="navIcons" />
                <span class="changepassword">{{'Customer Support'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item">
                <img src="/assets/icons/language.png" alt="changepassword" class="navIcons" />
                <span class="changepassword">{{'Language'|translate}} </span>
              </a>
            </li> -->
            <li>
              <a class="dropdown-item" routerLink="/main/accountssettings">
                <img src="/assets/icons/setting.png" alt="changepassword" class="navIcons" />
                <span class="changepassword" title="{{'Account Setting'|translate}}">{{'Account Setting'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" style="border-top: 3px solid #fff" (click)="onLogout()">
                <img src="/assets/icons/logout.png" alt="changepassword" class="navIcons" />
                <span class="changepassword" title="{{'Logout'|translate}}">{{'Logout'|translate}} </span>
              </a>
            </li>
          </ul>
        </div>
        
      </div>
      </div>
    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav me-auto d-flex align-items-center">
        <li class="nav-item me-4">
          <a
            routerLink="/main/dashboard"
            class="default"
            style="display: block"
            title="{{'Dashboard'|translate}}"            
          >
            <img src="/assets/icons/dashboard.svg" alt="desktop" class="pinkimg" routerLinkActive="selection-menu" />
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
          routerLink="/main/dashboard"
          style="display: none"
          routerLinkActive="['show']"
          >
            <img src="/assets/icons/desktop_pink.png" class="pinkimg" alt="pink" />
            <label>{{'Dashboard'|translate}}</label>
          </a>
        </li> -->
        <li class="nav-item me-4">
          <a
            routerLink="/main/tracking"
            class="default"
            style="display: block"
            title="{{'Tracking'|translate}}"            
          >
            <img src="/assets/icons/tracking.svg" class="pinkimg" alt="track" routerLinkActive="selection-menu" />
          </a>
        </li>
        <li class="nav-item me-4">
          <a
            style="display: block"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('tasklist')"
            title="{{'Task List'|translate}}"
          >
            <i class="bi bi-list-task" style="font-size: 3vh;
            color: gray;"></i>
            <!-- <img src="/assets/icons/report.svg" alt="report" class="pinkimg" routerLinkActive="selection-menu"/> -->
          </a>
        </li>
        <!-- <li class="nav-item me-4" *ngIf="isSupervisor1">
          <a
            style="display: block"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('taskadd')"
            title="{{'Task Add'|translate}}"
          >
            <i class="bi bi-plus-square" style="font-size: 3vh;
            color: gray;"></i>
          </a>
        </li> -->

        <li class="nav-item dropdown me-3" *ngIf="isSupervisor1">
          <a class="nav-link dropdown-toggle d-flex" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-plus-square" style="font-size: 3vh;color: gray;" routerLinkActive="selection-menu"></i>
          </a>

          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" >
            <a
            style="display: block"
            class="default text-decoration-none dropdown-item text-center"
            href="javascript:void(0);"
            (click)="openModel('taskadd');"
            title="{{'Upload Task'|translate}}"
            *ngIf="userData.administrator || userData.userLimit !== 0 || isBackend || isAdmin || isDieselManager"
          >{{'Upload Task'|translate}}
          </a>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('createtask')"
              title="{{'Create Task'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isBackend || isAdmin || isDieselManager">
              {{'Create Task'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('users')"
              title="{{'Users'|translate}}"
              *ngIf="isClient">{{'Users'|translate}}</a>
            </li>
          </ul>


        </li>


        <!-- <li class="nav-item">
          <a
            routerLink="/main/tracking"
            style="display: none"
            [routerLinkActive]="['show']"
          >
            <img src="/assets/icons/tracking_pink.png" class="pinkimg" alt="track" />
            <label>{{'Tracking'|translate}} </label>
          </a>
        </li> -->
        <!-- <li class="nav-item me-4" *ngIf="false">
          <a
            style="display: block"
            class="default text-decoration-none"
            routerLink="/main/geofences"
            title="{{'Geofence'|translate}}"
          >
            
            <i class="bi bi-heptagon icoimg" routerLinkActive="selection-menu"></i>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            style="display: none"
            routerLink="/main/geofences"
            [routerLinkActive]="['show']"
          >
            <img src="/assets/icons/disk_pink.png" alt="geo" class="pinkimg" />
            <label>{{'Geofence'|translate}} </label>
          </a>
        </li> -->
        <li class="nav-item me-3">
          <a
            style="display: block"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('reports')"
            title="{{'Reports'|translate}}"
          >
            <img src="/assets/icons/report.svg" alt="report" class="pinkimg" routerLinkActive="selection-menu"/>
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
            style="display: none"
            routerLink="/main/reports"
            [routerLinkActive]="['show']"
          >
            <img src="/assets/icons/reports_pink.png" alt="geo" class="pinkimg" />
            <label>{{'Reports'|translate}} </label>
          </a>
        </li> -->
        <li class="nav-item dropdown me-3">
          <!-- <div class="btn-group">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-person-fill icoimg" routerLinkActive="selection-menu"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-lg-end">
              <li><button class="dropdown-item" type="button">Action</button></li>
              <li><button class="dropdown-item" type="button">Another action</button></li>
              <li><button class="dropdown-item" type="button">Something else here</button></li>
            </ul>
          </div> -->
          <a class="nav-link dropdown-toggle d-flex" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person-fill icoimg" routerLinkActive="selection-menu"></i>
          </a>
          <!-- <button type="button" class="btn"><i class="bi bi-person-fill icoimg" routerLinkActive="selection-menu"></i></button>
          <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropdown</span>
          </button> -->
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('customer')"
              title="{{'Driver'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isBackend || isSupervisor || isAccountant || isCompliance || isAdmin || isDieselManager">{{'Driver'|translate}}</a></li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('dealer')"
              title="{{'Supervisor'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isAdmin">{{'Supervisor'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('clients')"
              title="{{'Companies'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isAdmin">{{'Companies'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('users')"
              title="{{'Users'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0  || isAdmin">{{'Users'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('users')"
              title="{{'Users'|translate}}"
              *ngIf="isClient">{{'Users'|translate}}</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            style="display: block;margin-top: 3px;"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('expense')"
            title="{{'Diesel'|translate}}"
            *ngIf="userData.administrator || userData.userLimit !== 0 || isDieselManager || isAdmin"
          >
            <img src="/assets/icons/dieselPump.svg"  alt="report" class="pinkimg" routerLinkActive="selection-menu"/>
          </a>
        </li>
        <!-- <li class="nav-item me-4" *ngIf="userData.administrator || userData.userLimit !== 0">
          
          <a
            style="display: block"
            class="default text-decoration-none"
            href="javascript:void(0);"
            (click)="openModel('customer')"
            title="{{'Driver'|translate}}"
            
          >
            <i class="bi bi-person-fill icoimg" routerLinkActive="selection-menu"></i>
          
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            style="display: none"
            routerLink="/main/user/customers"
            [routerLinkActive]="['show']"
            *ngIf="userData.administrator || userData.userLimit !== 0"
          >
            <img src="/assets/icons/user_pink.png" alt="Users" class="pinkimg" />
            <label>{{'Customer'|translate}} </label>
          </a>
        </li> -->
        <!-- routerLink="/main/user/dealers" -->
        <!-- <li class="nav-item me-4" *ngIf="userData.administrator || userData.userLimit !== 0">
          <a
            style="display: block"
            class="default text-decoration-none"
            href="javascript:void(0);"
            (click)="openModel('dealer')"
            title="{{'Supervisor'|translate}}"
            
          >
          <i class="bi bi-people-fill icoimg" routerLinkActive="selection-menu"></i>
            
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            style="display: none"
            routerLink="/main/user/dealers"
            [routerLinkActive]="['show']"
            *ngIf="userData.administrator || userData.userLimit !== 0"
          >
            <img src="/assets/icons/user_pink.png" alt="Users" class="pinkimg" />
            <label>{{'Dealer'|translate}} </label>
          </a>
        </li> -->
        <!-- <li class="nav-item me-4" *ngIf="userData.administrator || userData.userLimit !== 0">
          <a
            style="display: block"
            class="default text-decoration-none"
            href="javascript:void(0);"
            (click)="openModel('license')"
            title="{{'Licenses'|translate}}"
            
          >          
            <i class="bi bi-wallet2 icoimg" routerLinkActive="selection-menu"></i>
            
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            style="display: none"
            routerLink="/main/licenses"
            [routerLinkActive]="['show']"
            *ngIf="userData.administrator || userData.userLimit !== 0"
          >
            <img src="/assets/icons/money.png" alt="Users" class="pinkimg" />
            <label>{{'Licenses'|translate}} </label>
          </a>
        </li> -->
        <!-- <li class="nav-item me-4">
          <a
            style="display: block"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('devices')"
            title="{{'Devices'|translate}}"
          >
            <img src="/assets/icons/car-front.svg" alt="devices" class="pinkimg" routerLinkActive="selection-menu"/>            
            
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            style="display: none"
            routerLink="/main/devices"
            [routerLinkActive]="['show']"
          
          >
            <img src="/assets/icons/whitedevice.png" alt="Users" class="pinkimg" />
            <label>{{'Devices'|translate}} </label>
          </a>
        </li> -->
        <!-- <li class="nav-item me-4">
          <a
          *ngIf="userData.administrator || userData.userLimit !== 0"
            style="display: block"
            class="default text-decoration-none"
            href="javascript:void(0);"
            (click)="openModel('masterModel')"
            title="{{'Device Model'|translate}} "
          
          >
          <i class="bi bi-chat-right-dots icoimg"></i>
            
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            *ngIf="userData.administrator || userData.userLimit !== 0"
              style="display: none"
              routerLink="/main/modelMaster"
              [routerLinkActive]="['show']"
            
            >
              <img src="/assets/icons/desktop_pink.png" alt="Device Model" class="pinkimg" />
              <label>{{'Device Model'|translate}} </label>
            </a>
        </li> -->
        <li class="nav-item me-4">
          <!-- <span routerLink="/main/accountssettings"><img src="/assets/icons/GearSix.png" style="width: 5vh;" class="headicon" alt="setting" matTooltip="Settings" /></span>
          <span style="    margin-top: -7px;">
            <div (click)="loadNotification()" class="dropdown">
              <button class="btn dropdown-toggle" type="button" id="notificationDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="/assets/icons/BellRinging.png" style="width: 5vh;" class="headicon" alt="Bell" matTooltip="Notification" />
              </button>

              <ul class="dropdown-menu" aria-labelledby="notificationDropdown" class="dropdown-menu notif_height"
                data-popper-placement="bottom-start"
                style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 53.6px, 0px);">
                <li style="border-bottom: 2px solid black;" *ngFor="let notif of notifications">
                  <a class="dropdown-item">
                    <div class="d-flex flex-column"><span class="sentence">{{notif.deviceName}}-{{notif.type}}
                        </span><span class="timestamp">{{notif.eventTime |date:'EEEE, MMMM d, y, h:mm:ss a'}}</span></div>
                  </a>
                </li>
                <li class="text-center"><a class="link-dark" (click)="loadMoreNotification($event);">{{'Load More'|translate}}</a></li>
              </ul>
            </div>
          </span> -->
        </li>
      </ul>
      <!-- <form class="d-flex">
        <input class="form-control me-4" type="text" placeholder="Search">
        <button class="btn btn-primary" type="button">Search</button>
      </form> -->
    </div>
  </div>
</nav>
<app-customers *ngIf="isCustomer || isDealer"></app-customers>
<app-clients *ngIf="isClients"></app-clients>
<app-licenses *ngIf="isLicense"></app-licenses>
<app-reports *ngIf="isReports"></app-reports>
<app-devices *ngIf="isDevices"></app-devices>
<app-model-master *ngIf="isMasterModel"></app-model-master>
<app-task-list *ngIf="isTaskList" [driverList]="driverList1" [dealerList]="dealerList"></app-task-list>
<app-task-add *ngIf="isTaskAdd"></app-task-add>
<app-task-edit *ngIf="isTaskEdit" [taskData]="editTaskData"></app-task-edit>
<app-users *ngIf="isUsers"></app-users>
<app-createtask *ngIf="isCreatetask"></app-createtask>
<app-expense *ngIf="isExpenses" [driverList]="driverList1"></app-expense>
<app-change-password *ngIf="isPasswordChange"></app-change-password>
<!-- <div class="container-fluid">
  <div class="row"> -->
    <!-- <div class="p-0 col-xl-3 col-xxl-3 col-lg-3 col-md-12 col-12 order-1"> -->
      <!-- <form class="example-form">
        
        <mat-form-field [class.d-none]="isTrackingScreen" class="example-full-width" appearance="fill">
          <mat-label>{{'Search Vehicle'|translate}}</mat-label>
          <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
            [matAutocomplete]="auto" />
          <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete"
            (optionSelected)="getVehicleDeviceID($event.option.value)">
            <mat-option [value]="'All Vehicle'">
              All Vehicle
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <ng-container >
          
           <mat-form-field [class.d-none]="!isTrackingScreen" class="example-full-width" appearance="fill">
            <mat-label>{{'Search Vehicle'|translate}}</mat-label>
            
              <input type="text" #searchDevice matInput [formControl]="myInputSearch" 
              placeholder="{{'Search Vehicle'|translate}}">
          </mat-form-field>
        </ng-container>
      </form> -->
    <!-- </div> -->

    <!-- <div class="
        p-0
        headIcons
        offset-xxl-4 offset-xl-4 offset-lg-0 offset-md-0 offset-sm-0
        col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-6
        order-2
      "> -->
      <!-- <span class="material-icons headericonrefresh" matTooltip="Refresh" (click)="refreshComponent()">
        refresh
      </span>  -->
      <!-- <span><img src="/assets/icons/report_refresh.png" class="headicon" style="width: 5vh; object-fit: scale-down"
          alt="Refresh" matTooltip="Refresh" (click)="refreshComponent()" /></span> -->
      <!-- <span routerLink="/main/accountssettings"><img src="/assets/icons/GearSix.png" style="width: 5vh;" class="headicon" alt="setting" matTooltip="Settings" /></span>
      <span style="    margin-top: -7px;">
        <div (click)="loadNotification()" class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="notificationDropdown" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src="/assets/icons/BellRinging.png" style="width: 5vh;" class="headicon" alt="Bell" matTooltip="Notification" />
          </button>

          <ul class="dropdown-menu" aria-labelledby="notificationDropdown" class="dropdown-menu notif_height"
            data-popper-placement="bottom-start"
            style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 53.6px, 0px);">
            <li style="border-bottom: 2px solid black;" *ngFor="let notif of notifications">
              <a class="dropdown-item">
                <div class="d-flex flex-column"><span class="sentence">{{notif.deviceName}}-{{notif.type}}
                    </span><span class="timestamp">{{notif.eventTime |date:'EEEE, MMMM d, y, h:mm:ss a'}}</span></div>
              </a>
            </li>
            <li class="text-center"><a class="link-dark" (click)="loadMoreNotification($event);">{{'Load More'|translate}}</a></li>
          </ul>
        </div>
      </span> -->
    <!-- </div> -->
<!-- 
    <div style="text-align: -webkit-right;" [class.is_Back_To_Admin]="isBackToAdmin" class=" 
        p-0
          offset-lg-0 offset-md-0 offset-sm-0
        col-xl-3 col-xxl-3 col-lg-3 col-md-6 col-6
        order-3
      ">
      
      <div class="user_profile">
        <button *ngIf="isBackToAdmin" (click)="backToAdmin()" title="Back To Admin" type="button" class="m-2 btn btn-outline-dark"> <i class="fa fa-sign-out pl-2" aria-hidden="true"></i></button>
        <div class="user-text">
          <span class="username">{{ username }}</span>
          <span class="userid"><small>{{ useremail }}</small></span>
          <span *ngIf="userDetails && !userDetails.administrator && userDetails.userLimit !== 0" class="userid"><i class="text-warning fas fa-coins"></i> {{userDetails.availableLicenses ? userDetails.availableLicenses : 0 }}</span>
        </div>

        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src="/assets/images/profile.png" style="width: 5vh;" alt="profile" matTooltip="Profile" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" style="border-top: 3px solid #fff">
                <img src="/assets/icons/changepassword.png" alt="changepassword" class="navIcons" />
                <span class="changepassword"> {{'Change Password'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item">
                <img src="/assets/icons/customer_support.png" alt="changepassword" class="navIcons" />
                <span class="changepassword">{{'Customer Support'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item">
                <img src="/assets/icons/language.png" alt="changepassword" class="navIcons" />
                <span class="changepassword">{{'Language'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/main/accountssettings">
                <img src="/assets/icons/setting.png" alt="changepassword" class="navIcons" />
                <span class="changepassword">{{'Account Setting'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" style="border-top: 3px solid #fff" (click)="onLogout()">
                <img src="/assets/icons/logout.png" alt="changepassword" class="navIcons" />
                <span class="changepassword">{{'Logout'|translate}} </span>
              </a>
            </li>
          </ul>
        </div>
        
      </div>
      </div> -->
    
<!-- </div> -->