import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { SubjectSubscribeService } from 'src/app/services/subject-subscribe.service';
import { CommonService } from '../../services/common.service';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-device',
  templateUrl: './search-device.component.html',
  styleUrls: ['./search-device.component.css']
})
export class SearchDeviceComponent implements OnInit {
  @ViewChild('searchDevice') searchDevice: ElementRef;
  myControl = new FormControl();
  myInputSearch = new FormControl();
  deviceDetails = [];
  DeviceID: string;
  isTrackingScreen=false;
  username = '';
  useremail = '';
  userDetails:any;
  orgId:any;
  userData:any ;
  filteredOptions: Observable<any[]>;
  constructor(private dash_http: DashboardDataService,
    private commonService: CommonService,
    private subjectSubscribeService: SubjectSubscribeService,
    private router: Router) { }

  ngOnInit(): void {


    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);
        if(this.userData.attributes.role ==='admin'){
          this.orgId= this.userData.id;
        }
        else{
          this.orgId = this.userData.orgId;
        }
        
      } catch (error) {}
    }
    this.isTrackingScreen =  this.router && this.router.url == '/main/tracking';
    setTimeout(()=>{
      //this.addedSearchEvent();
    },100)
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  )
      .subscribe(event => {
        this.isTrackingScreen =  event && event['url'] == '/main/tracking';
        if(this.isTrackingScreen) {
          this.myInputSearch.setValue('');
          setTimeout(()=>{
            //this.addedSearchEvent();
          },1)
        }
      });
    // this.onShareProfileImage();
    this.getDeviceName();

    this.commonService.getUserDetails().subscribe((resp: any) => {
      if (resp) {
        this.userDetails = resp;
        this.username = resp.name;
        this.useremail = resp.email;
      }
    });
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.deviceDetails.slice();
      }),
    );
  }
  displayFn(vehicle: any): string {
    return vehicle && vehicle.name ? vehicle.name : '';
  }
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.deviceDetails.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  ngAfterViewInit() {    
    this.addedSearchEvent();
  }
  addedSearchEvent() {
    fromEvent(this.searchDevice.nativeElement,'keyup')
      .pipe(
          filter(Boolean),
          debounceTime(300),
          distinctUntilChanged(),
          tap((text) => {
            console.log(this.searchDevice.nativeElement.value);
            this.subjectSubscribeService.searchDevice.next(this.searchDevice.nativeElement.value)
          })
      )
      .subscribe();
  }
  getDeviceName() {
   
   this.commonService.getRequest(`devices/orgId/${this.orgId}`, []).then((resp: any) => {
         
    if (resp?.status) {
      this.deviceDetails = resp.data;
      localStorage.setItem(
        'vehicleIDArray',
        JSON.stringify(this.deviceDetails)
      );
      
      // TODO: handle by dashboard url
      this.myControl.setValue(this.deviceDetails[0]);
      this.getVehicleDeviceID(this.deviceDetails[0]);
    }
  });
    // this.commonService.getRequest('devices?all=all', []).then((resp: any) => {

    //   if (resp?.status) {
    //     this.deviceDetails = resp.data;
    //     localStorage.setItem(
    //       'vehicleIDArray',
    //       JSON.stringify(this.deviceDetails)
    //     );
        
    //     // TODO: handle by dashboard url
    //     this.myControl.setValue(this.deviceDetails[0]);
    //     this.getVehicleDeviceID(this.deviceDetails[0]);
    //   }
    // });
  }
  getVehicleDeviceID(value) {
 
    //let filterData = this.deviceDetails.find((x) => x.name === value.name);
    this.DeviceID = value ?  value.id : '';
    // this.dash_http.DeviceId.next(this.DeviceID);
    this.subjectSubscribeService.DeviceId.next(this.DeviceID);
  }
  refreshComponent() {
    this.dash_http.getDashbaord().subscribe((resp) => {
      console.log(resp);
      this.dash_http.vehiclesCountComponent.next(true);
    });
  }
  changeSearchTxt() {
    this.subjectSubscribeService.searchDevice.next(this.myInputSearch.value);
  }
}
