import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $:any
@Component({
  selector: 'app-route-modal',
  templateUrl: './route-modal.component.html',
  styleUrls: ['./route-modal.component.css'],

})

export class RouteModalComponent implements OnInit,AfterViewInit{
  injectedData: any;
  taskStatusForm: FormGroup;

  selectedTaskStatus: string = '';
  startLat:any;
  startLon:any;
  endLat:any;
  endLon:any;

  private map: google.maps.Map | undefined;
  deviceDetails: any;
  selectedDevice: any;
  device_id: any;
  oneVehicle: boolean;
  allvehicles: boolean;
  selcetvehicle: any;
  origin_lat: any;
  origin_lng: any;
  selectedDeviceNetworkData: any;
  markersList = [];
  deviceList=[];
  driverDetails: any;
  my_string: string;
  lat: any;
  lng: any;
  zoom: number;
  secondLastLat: any;
  secondLastLng: number;
  mark: any;
  historyPath: any = {};
  polyLineArray = [];
  initialRoute=[];
  destination1:any =[];
  initialBounds: google.maps.LatLngBounds;
  path: google.maps.Polyline;

  icon = 'src/assets/icons/greencar.png';
  tripRoute: google.maps.LatLng[];
  pickupMarker: google.maps.Marker;
  dropMarker: google.maps.Marker;
  originToDeviceRoute: google.maps.LatLng[];
  clientLocation: string;
  dropLocation: any;
  pickupLocation: any;
  deviceTime: any;
 status:any='';
 actual_emp:any='';
 planned_emp:any='';
 escort:any='';
 id:any='';
 type:any='';
 Shift_Timing:any='';
 clientid:any='';
 pickuplocationt:any='';
 droplocationt:any='';
 cabno:any=''
 @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;
  constructor(public dialogRef: MatDialogRef<RouteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private commonService:CommonService, private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private zone: NgZone
    ) {
      this.injectedData = data;
      console.log(this.injectedData);
     }
  ngAfterViewInit() {
    // this.ngZone.runOutsideAngular(() => {
    //   this.calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, 0);
    // });
  }
directionsService = new google.maps.DirectionsService();
directionsRenderer = new google.maps.DirectionsRenderer();
  ngOnInit(): void {
    this.startLat=this.injectedData.startlatitudelocation
    this.startLon=this.injectedData.startlongitudelocation
    this.endLat =this.injectedData.endlatitudelocation
    this.endLon =this.injectedData.endlongitudelocation
    if(this.injectedData.type === 'pickup' || this.injectedData.type === 'Pickup' ){
      this.getDeviceAddress1(this.endLat,this.endLon);//client location -->drop location
      
    }
    else if(this.injectedData.type === 'drop' || this.injectedData.type === 'Drop'){
      this.getDeviceAddress1(this.startLat,this.startLon);//client location -->pickup location
    }
    this.db_calculateAndDisplayRoute(this.directionsService,this.directionsRenderer,0,this.startLat,this.startLon); 
    this.getDeviceInformationByUserId(this.injectedData.assignedto);
    this.destination1 = new google.maps.LatLng(this.endLat, this.endLon);
    this.status=this.injectedData.status;
    this.actual_emp=this.injectedData.attributes?.actual_emp
    this.planned_emp=this.injectedData.attributes?.planned_emp
    this.id=this.injectedData.id
    this.escort=this.injectedData.attributes?.escort
    this.type=this.injectedData.type
    this.pickuplocationt=this.getSlicedAddress(this.injectedData.pickuplocation);
    this.droplocationt=this.getSlicedAddress(this.injectedData.droplocation);
    this.clientid=this.injectedData.clientid;
    this.cabno=this.injectedData.attributes?.cabno
    this.Shift_Timing=this.injectedData.attributes?.Shift_Timing
    
  }

  closeModal(): void {
    this.commonService.closeSocket();
    this.dialogRef.close();
  }

getDeviceAddress1(latitude,longitude) {
  let params = [
    'latitude=' + longitude,
    'longitude=' + latitude,
    'maxDistance=1000'
  ]
    this.commonService.getRequest('statistics/getAddress?' + params.join('&'), [])
    .then((resp: any) => {
      if(resp.data && resp.data.address){
        console.log('address',resp.data.address);
        if(this.injectedData.type === 'pickup'){
          this.dropLocation =resp.data.address;
          this.pickupLocation= this.injectedData.pickuplocation;
        }
        else if(this.injectedData.type === 'drop'){
          this.dropLocation = this.injectedData.droplocation;
          this.pickupLocation=resp.data.address;
        }
      
       console.log('client location',this.clientLocation)
      }
    }).finally(()=>{
     
    });
}

db_calculateAndDisplayRoute(
  directionsService: any,
  directionsRenderer: any,
  index: any,
  newLat: any,
  newLon: any
) {
  this.initialRoute=[];
  var origin = new google.maps.LatLng(newLat, newLon);
  var ways = [];
  directionsService
    .route({
      origin: `${this.startLat},${this.startLon}`,
      destination:`${this.endLat},${this.endLon}` ,
      waypoints: ways,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((response: google.maps.DirectionsResult) => {
   this.tripRoute=response.routes[0].overview_path;   
   this.initMap();
   this.getConnection();
    })
    .catch((e: any) => console.log('Directions request failed due to ' + e));
}
movingMarker: any;
initMap() {
  this.map = new google.maps.Map(document.getElementById('mymap'), {
    center: { lat: 0, lng: 0 },
    zoom: 12 
  });
  if (this.tripRoute) {
    this.path = new google.maps.Polyline({
      path: this.tripRoute,
      geodesic: true,
      strokeColor: '#FF0000', 
      strokeOpacity: 1.0,
      strokeWeight: 2,
      map: this.map 
    });
   this.pickupMarker = new google.maps.Marker({
      position: this.tripRoute[0], 
      map: this.map,
      title: `${this.pickupLocation}`,
      icon: 'https://maps.google.com/mapfiles/kml/paddle/P.png'
    });
    this.dropMarker = new google.maps.Marker({
      position: this.tripRoute[this.tripRoute.length - 1],
      map: this.map,
      title: `${this.dropLocation}`,
      icon: 'https://maps.google.com/mapfiles/kml/paddle/D.png'
    });
    const bounds = new google.maps.LatLngBounds();
    this.tripRoute.forEach(point => bounds.extend(point));
    bounds.extend(this.pickupMarker.getPosition());
    bounds.extend(this.dropMarker.getPosition());
    this.map.fitBounds(bounds);
    this.initialBounds = this.map.getBounds();
  }
}

getDeviceInformationByUserId(val){

  this.commonService
  .getRequest(
    'devices/userid/' +
    val,
    []
  )
  .then((resp: any) => {  

    this.device_id=resp.data.id;
    console.log('device details',this.device_id);
   
  });
}

    getConnection() {
      //close sesstion if aleady exits
      debugger
      try {
        this.commonService.closeSocket();
      } catch (error) {
        // no open connection found
      }
      const basicToken = localStorage.getItem('socketBasicToken');
      this.commonService.socketConnection = new WebSocket(
        `wss://${environment.baseUrl}:8082/api/socket?token=` + basicToken
      );
      if (this.commonService.socketConnection) {
        this.commonService.socketConnection.onmessage = (event) => {
          var msg = JSON.parse(event.data);
       
          if (msg.devices) {
 
              console.log('device offline info',msg.devices);
             
          }
          else if (msg.positions) {
            console.log("Positions list=>",msg.positions)                    
            if(this.injectedData.status=== 'started' || this.injectedData.status=== 'dropped' || this.injectedData.status=== 'pickedup'){
              console.log(msg.positions)
              this.markersList=msg.positions.filter(device => device.deviceId
                 === this.device_id)
              console.log("Positions=>", this.markersList);
              console.log('After updating deviceTime:', this.deviceTime);
              this.zone.run(() => {
              
                this.deviceTime = this.markersList[0]?.serverTime;
                $('#deviceTime').text(this.convertToISOWithAMPM(this.deviceTime))
                 this.cdr.detectChanges();
                 console.log('After updating deviceTime:', this.deviceTime);
            
            });
              // this.updateMovingMarker(this.markersList[0].latitude,this.markersList[0].longitude);
              const currentPosition = { lat: this.markersList[0].latitude, lon:this.markersList[0].longitude };
              // this.db_calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, '0', this.markersList[0].latitude,this.markersList[0].longitude);
              this.updateRoute(currentPosition);
            }   
            else if( this.injectedData.status==='assigned'){
              this.markersList=msg.positions.filter(device => device.deviceId
                === this.device_id)
             console.log("Positions=>", this.markersList);
             this.zone.run(() => {
              this.deviceTime = this.markersList[0]?.serverTime;
              $('#deviceTime').text(this.convertToISOWithAMPM(this.deviceTime))
               this.cdr.detectChanges();
               console.log('After updating deviceTime:', this.deviceTime);
          });

             // this.updateMovingMarker(this.markersList[0].latitude,this.markersList[0].longitude);
             const currentPosition = { lat: this.markersList[0].latitude, lon:this.markersList[0].longitude };
             // this.db_calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, '0', this.markersList[0].latitude,this.markersList[0].longitude);
             this.updateRoute_for_assigned(currentPosition);
            }
            else if( this.injectedData.status==='acknowledged'){
              this.markersList=msg.positions.filter(device => device.deviceId
                === this.device_id)
             this.zone.run(() => {   
              this.deviceTime = this.markersList[0]?.serverTime;
              $('#deviceTime').text(this.convertToISOWithAMPM(this.deviceTime))
               this.cdr.detectChanges();
               console.log('After updating deviceTime:', this.deviceTime);
          });

             // this.updateMovingMarker(this.markersList[0].latitude,this.markersList[0].longitude);
             const currentPosition = { lat: this.markersList[0].latitude, lon:this.markersList[0].longitude };
             // this.db_calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, '0', this.markersList[0].latitude,this.markersList[0].longitude);
             this.updateRoute_for_acknowledged(currentPosition);
            }
            
        } 
        };
      
        this.commonService.socketConnection.onopen = function (event) {
          // this.commonService.socketConnection.send(
          //   "Here's some text that the server is urgently awaiting!"
          // );
        };
  
        this.commonService.socketConnection.onclose = function (event) {
          // By the server/me or both?
          console.log('Connection closed');
        };
      }
      
    } 
    roatedDevice(device, refreshTime = 100, isCourse = 0) {

      setTimeout(() => {
        const element = document.querySelector(
          `img[src~='${device.deviceDetails.vehicleMapImg.url}']`
        ) as HTMLImageElement;
        if (element) {
          element.style.transform =
            'rotate(' + (isCourse ? isCourse : device.course) + 'deg)';
        }
      }, refreshTime);
    } 
    animatedMove(marker, current, moveto) {
      let deltalat = (moveto.latitude - current.latitude) / 500;
      let deltalng = (moveto.longitude - current.longitude) / 500;
      let i = 0;
      function moveItSelf(){
        setTimeout( ()=> {
          let lat = marker.latitude;
          let lng = marker.longitude;
          lat += deltalat;
          lng += deltalng;
          //let latlng = new google.maps.LatLng(lat, lng);
          marker.latitude = lat;
          marker.longitude = lng;
          if(i<500 && this && !this.oneVehicle) {
            i++;
            moveItSelf();
          } else {
            marker.latitude = moveto.latitude;
            marker.longitude = moveto.longitude;
          }
        }, 10);
      }
      moveItSelf();
      
    }

    convertToISOWithAMPM(dateString: string): string {
      const date = new Date(dateString);
      return this.datePipe.transform(date, 'yyyy-MM-dd hh:mm:ss a', '+0530');
    }
 updateMovingMarker(lat, lon) {
  if (!this.movingMarker) {
    console.error('Moving marker is not initialized.');
    return;
  }

  var newLatLng = new google.maps.LatLng(lat, lon);
  this.movingMarker.setPosition(newLatLng);
    }
   
   updateRoute(currentPosition) {
      const currentLatLng = new google.maps.LatLng(currentPosition.lat, currentPosition.lon);
      const waypoints = [];
      const carIcon = {
        url: '/assets/icons/running_car.png', 
        scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
        origin: new google.maps.Point(0, 0), 
        anchor: new google.maps.Point(25, 25) 
      };


      if (!this.movingMarker) {
        this.movingMarker = new google.maps.Marker({
          position: currentLatLng,
          map: this.map,
          icon: carIcon,
          // title: `${this.injectedData.attributes.driverName}`
        });
      //   const infoWindowContent = `
      //   <div>
      //     <p><strong>Driver Name:</strong> ${this.injectedData.attributes.driverName}</p>
      //     <p><strong>Cab Number:</strong> ${this.injectedData.attributes.cabno}</p>
      //     <p><strong>Trip id:</strong> ${this.injectedData.id}</p>
      //     <p><strong>Client name:</strong> ${this.injectedData.clientid}</p>
      //     <p><strong>Last update time:</strong> ${this.deviceTime}</p>
      //   </div> 
      // `;

//       const infoWindowContent = `
//       <div class="d-flex align-items-center justify-content-between card-header" style="
// padding-bottom: 12px;
// background-color: #ffd892;
// margin-left: 4px;
// padding-top: 15px;
// ">
// <span class="badge rounded-pill" [ngClass]="getBgColorBasedOnTaskStatus(${this.status})">${this.status}
// </span>
// <div class="d-flex">
//   <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(${this.status})">
//     <span class="font-size-18">
//     ${this.actual_emp}
//     </span>
//     <span>Actual Emp</span>
//   </span>
//   <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(${this.status})">
//     <span class="font-size-18">
//      ${this.planned_emp}
//       </span>
//     <span>Planned Emp</span>
//   </span>
//   <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(${this.status})">
//     <span class="font-size-18">
//     ${this.escort} 
//     </span>
//     <span>Escort</span>
//   </span>
// </div>

// </div>
// <div class="col-12">
// <div class="row mt-2">
//   <div class="col-9">
//     <div>
//       <span class="fw-bold"><i class="fa-solid fa-id-card"></i></span>
//       <span class="ms-1">${this.id}</span>&nbsp;&nbsp;
//       <span class="fw-bold"><i
//           class="fa-solid fa-route"></i></span>
//       <span class="ms-1">${this.type}</span>&nbsp;&nbsp;
//       <span>
//         <img src="/assets/icons/vehicletimer.png" alt="Time" />
//       </span>
//       <span class="ms-1">${this.Shift_Timing}</span>
//     </div>
//     <div>
//       <span class="fw-bold">Client: </span>
//       <span class="ms-1">${this.clientid}</span>
//     </div>
//     <div>
//       <span class="fw-bold">Address: </span>
//       <span *ngIf="${this.type} == 'pickup'" class="ms-1">
//        ${this.pickuplocationt}
//       </span>
//       <span *ngIf="${this.type} == 'drop'" class="ms-1">
//    ${this.droplocationt}
//       </span>
//     </div>
//   </div>


//   </div>
//   <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column"
//     >
//     <i class="bi bi-person-circle font-size-40"></i>
//     <span class="fw-bold font-size-12">${this.cabno}</span>
//   </div>
// \
// </div>
//       `
      
      // const infoWindow = new google.maps.InfoWindow({
      //   content: infoWindowContent
      // });

      // this.movingMarker.addListener('mouserhover', () => {
      //   // Open the info window when hovering over the marker
      //   infoWindow.open(this.map, this.movingMarker);
      // });
      this.movingMarker.addListener('click', () => {
        // Open the info window when hovering over the marker
        infoWindow.open(this.map, this.movingMarker);
      });
      // Add a mouseout event listener to the marker
      // this.movingMarker.addListener('mouseout', () => {
      //   // Close the info window when moving away from the marker
      //   infoWindow.close();
      // });
      const infoWindowContent = this.mapContainer.nativeElement.innerHTML;
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent
      });
      infoWindow.addListener('domready', () => {
        const infoWindowElement = document.querySelector('.gm-style-iw') as HTMLElement;
       const infoWindowElement1=document.querySelector ('gm-style-iw-d') as HTMLElement;
        if (infoWindowElement) {
          // Apply custom CSS to remove padding
          infoWindowElement.style.padding = '0';
          infoWindowElement.style.overflow = 'visible';
        }
        if (infoWindowElement1) {
          // Apply custom CSS to remove padding
         
          infoWindowElement.style.right = '0';
          infoWindowElement.style.bottom = '0';
        }
   
      });  
    
      // Open the info window when marker is clicked
      this.movingMarker.addListener('click', () => {
        infoWindow.open(this.map, this.movingMarker);
      });
      
      } else {
        this.movingMarker.setPosition(currentLatLng);
      }
      this.directionsService.route({
        origin: `${this.startLat},${this.startLon}` ,
        destination: currentLatLng,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },(response, status)=>{
        if (status === 'OK') {
        this.originToDeviceRoute=response.routes[0].overview_path
        } else {
          console.error('Directions request failed due to ' + status);
        }
      })
      this.directionsService.route({
        origin: currentLatLng,
        destination: `${this.endLat},${this.endLon}`,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },(response, status)=>{
        if (status === 'OK') {
      
          const overviewPath = response.routes[0].overview_path;
          const updatedRoute: google.maps.LatLng[] = [];
          updatedRoute.push(currentLatLng)
          updatedRoute.push(...overviewPath);
          const combinedRoute = [ ...this.originToDeviceRoute, ...overviewPath];
          if (this.path) {
            this.path.setPath(combinedRoute);
          } else {   
            console.error('Polyline path is not initialized.');
          }
          const bounds = new google.maps.LatLngBounds();
          combinedRoute.forEach(point => bounds.extend(point));
          bounds.extend(this.pickupMarker.getPosition());
          bounds.extend(this.dropMarker.getPosition());
          if (this.initialBounds) {
            this.map.fitBounds(this.initialBounds);
          }
        } else {
          console.error('Directions request failed due to ' + status);
        }
      })
     
  
    }

    updateRoute_for_assigned(currentPosition) {
      const currentLatLng = new google.maps.LatLng(currentPosition.lat, currentPosition.lon);
      const waypoints = [];
      const carIcon = {
        url: '/assets/icons/red_car.png', 
        scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
        origin: new google.maps.Point(0, 0), 
        anchor: new google.maps.Point(25, 25) 
      };


      if (!this.movingMarker) {
        this.movingMarker = new google.maps.Marker({
          position: currentLatLng,
          map: this.map,
          icon: carIcon,
        });
   
      this.movingMarker.addListener('click', () => {
        infoWindow.open(this.map, this.movingMarker);
      });
   
      const infoWindowContent = this.mapContainer.nativeElement.innerHTML;
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent
      });
  
  
      this.movingMarker.addListener('click', () => {
        infoWindow.open(this.map, this.movingMarker);
      });
      
      } else {
        this.movingMarker.setPosition(currentLatLng);
      }
      // this.directionsService.route({
      //   origin: `${this.startLat},${this.startLon}` ,
      //   destination: `${this.endLat},${this.endLon}`,
      //   waypoints: waypoints,
      //   travelMode: google.maps.TravelMode.DRIVING,
      // },(response, status)=>{
      //   if (status === 'OK') {
      //   this.originToDeviceRoute=response.routes[0].overview_path
      //   } else {
      //     console.error('Directions request failed due to ' + status);
      //   }
      // })
      this.directionsService.route({
        origin: `${this.startLat},${this.startLon}`,
        destination: `${this.endLat},${this.endLon}`,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },(response, status)=>{
        if (status === 'OK') {
          const overviewPath = response.routes[0].overview_path;
          const updatedRoute: google.maps.LatLng[] = [];
          // updatedRoute.push(currentLatLng)
          updatedRoute.push(...overviewPath);
          const combinedRoute = [...overviewPath];
          if (this.path) {
            this.path.setPath(combinedRoute);
          } else {   
            console.error('Polyline path is not initialized.');
          }
          const bounds = new google.maps.LatLngBounds();
          combinedRoute.forEach(point => bounds.extend(point));
          bounds.extend(this.pickupMarker.getPosition());
          bounds.extend(this.dropMarker.getPosition());
          if (this.initialBounds) {
            this.map.fitBounds(this.initialBounds);
          }
        } else {
          console.error('Directions request failed due to ' + status);
        }
      })
     
  
    }
    updateRoute_for_acknowledged(currentPosition) {
      const currentLatLng = new google.maps.LatLng(currentPosition.lat, currentPosition.lon);
      const waypoints = [];
      const carIcon = {
        url: '/assets/icons/red_car.png', 
        scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
        origin: new google.maps.Point(0, 0), 
        anchor: new google.maps.Point(25, 25) 
      };


      if (!this.movingMarker) {
        this.movingMarker = new google.maps.Marker({
          position: currentLatLng,
          map: this.map,
          icon: carIcon,
        });
   
      this.movingMarker.addListener('click', () => {
        infoWindow.open(this.map, this.movingMarker);
      });
   
      const infoWindowContent = this.mapContainer.nativeElement.innerHTML;
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent
      });
  
  
      this.movingMarker.addListener('click', () => {
        infoWindow.open(this.map, this.movingMarker);
      });
      
      } else {
        this.movingMarker.setPosition(currentLatLng);
      }
  
      this.directionsService.route({
        origin: `${this.startLat},${this.startLon}`,
        destination: `${this.endLat},${this.endLon}`,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },(response, status)=>{
        if (status === 'OK') {
          const overviewPath = response.routes[0].overview_path;
          // const updatedRoute: google.maps.LatLng[] = [];
          // // updatedRoute.push(currentLatLng)
          // updatedRoute.push(...overviewPath);
          const combinedRoute = [...overviewPath];
          if (this.path) {
            this.path.setPath(combinedRoute);
          } else {   
            console.error('Polyline path is not initialized.');
          }
          const bounds = new google.maps.LatLngBounds();
          combinedRoute.forEach(point => bounds.extend(point));
          bounds.extend(this.pickupMarker.getPosition());
          bounds.extend(this.dropMarker.getPosition());
          if (this.initialBounds) {
            this.map.fitBounds(this.initialBounds);
          }
        } else {
          console.error('Directions request failed due to ' + status);
        }
      })
     
    
    }
    getSlicedAddress(address: string): string {
      // Check if the address contains 'Maharashtra'
      const indexMaharashtra = address.indexOf('Maharashtra');
      if (indexMaharashtra !== -1) {
        const parts = address.split('Maharashtra');
        const lastPartWords = parts[0].trim().split(' ');
        const lastIndex = lastPartWords.length - 1;
        let slicedAddress = address.slice(0, address.lastIndexOf(lastPartWords[lastIndex])).trim();
        slicedAddress = slicedAddress.replace(/,\s*$/, '');
        return slicedAddress;
      } else {
       return address;
      }
    }  

    getBgColorBasedOnTaskStatus(status: any) {
      let statusclasses = {
        "task-status-unassigned": status == "unassigned",
        "task-status-assigned": status == "assigned",
        "task-status-acknowledge": status == "acknowledged",
        "task-status-progress": status == "inprogress",
        "task-status-completed": status == "completed",
        "task-status-started":status == "started",
        "task-status-dropped":status== 'dropped',
        "task-status-pickedup":status== 'pickedup'
      };
      return statusclasses;
    }
}





